import React, { useState, useEffect, useRef, useCallback } from 'react'
import Modal from './Components/Modal'
import './App.css';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import axios from 'axios'

const App = ({ scrollPosition }) => {
  const [query, setQuery] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [ids, setIds] = useState([])
  const [imageUrls, setImageUrls] = useState([])
  const [titles, setTitles] = useState([])
  const [artists, setArtists] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [totalImages, setTotalImages] = useState(0)
  const form = useRef(null)
  const searchRef = useRef()
  const categoryRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [currentTitle, setCurrentTitle] = useState(null)
  const [currentArtist, setCurrentArtist] = useState(null)

  const displayUrl = "https://collectionapi.metmuseum.org/public/collection/v1/objects/"
  const department = "https://collectionapi.metmuseum.org/public/collection/v1/search?departmentId="
  

  const fetchData = (e) => {
    e.preventDefault()
    setImageUrls([])
    setLoading(true)
    setError(false)
    let cancel
    
    axios.get(department + categoryRef.current.value + '&q=' + searchRef.current.value)
        .then(res => {
            setIds(res.data.objectIDs)
            setTotalPages(res.data.objectIDs.length / 6)
            for(let i = 0; i < 100; i++) {
              axios.get(displayUrl + res.data.objectIDs[i]).then(res => {
                  
                  let image = res.data.primaryImage
                  let artist = res.data.artistDisplayName
                  let title = res.data.title
                  setImageUrls(imageUrls => [...imageUrls, image])
                  setArtists(artists => [...artists, artist])
                  setTitles(titles => [...titles, title])
                  setHasMore(res.data.length > 0)
                  setLoading(false)
                  setTotalImages(res.data.length)
              })
          } 
          
    })
    
  }

  console.log(imageUrls)


  // const observer = useRef()
  // const lastImageElementRef = useCallback(node => {
  //   if (loading) return
  //   if (observer.current) observer.current.disconnect()
  //   observer.current = new IntersectionObserver(entries => {
  //     if (entries[0].isIntersecting && hasMore) {
  //       setPageNumber(prevPageNumber => prevPageNumber + 1)
  //     }
  //   })
  //   if(node) observer.current.observe(node)
  //   console.log(node)
  // }, [loading, hasMore])


  const configCategories = {
    options: [
      {
        "departmentId": 1,
        "displayName": "American Decorative Arts"
      },
      {
        "departmentId": 3,
        "displayName": "Ancient Near Eastern Art"
      },
      {
        "departmentId": 4,
        "displayName": "Arms and Armor"
      },
      {
        "departmentId": 5,
        "displayName": "Arts of Africa, Oceania, and the Americas"
      },
      {
        "departmentId": 6,
        "displayName": "Asian Art"
      },
      {
        "departmentId": 7,
        "displayName": "The Cloisters"
      },
      {
        "departmentId": 8,
        "displayName": "The Costume Institute"
      },
      {
        "departmentId": 9,
        "displayName": "Drawings and Prints"
      },
      {
        "departmentId": 10,
        "displayName": "Egyptian Art"
      },
      {
        "departmentId": 11,
        "displayName": "European Paintings"
      },
      {
        "departmentId": 12,
        "displayName": "European Sculpture and Decorative Arts"
      },
      {
        "departmentId": 13,
        "displayName": "Greek and Roman Art"
      },
      {
        "departmentId": 14,
        "displayName": "Islamic Art"
      },
      {
        "departmentId": 15,
        "displayName": "The Robert Lehman Collection"
      },
      {
        "departmentId": 16,
        "displayName": "The Libraries"
      },
      {
        "departmentId": 17,
        "displayName": "Medieval Art"
      },
      {
        "departmentId": 18,
        "displayName": "Musical Instruments"
      },
      {
        "departmentId": 19,
        "displayName": "Photographs"
      },
      {
        "departmentId": 21,
        "displayName": "Modern Art"
      }
    ]
  }

  return (
  <div className="wrapper">
      <div className="search-area">
        <h1>Search<span className="cursive">the</span><span className="times">Met</span></h1>
          <form className='search-form' onSubmit={fetchData}>
            <label className="label" htmlFor="categories">
                Select a Category:
            </label>
            <select id="categories" className="categories" ref={categoryRef}>
                {configCategories.options.map((option, index) => {
                    const { departmentId, displayName } = option
                    
                    return (
                        <option key={index} value={departmentId}>{displayName}</option>
                        )
                    })}
            </select>
            <label htmlFor="search" className="label">Searching For:</label>
            <input className="search-input" id="search" type="text" placeholder="Anything..." ref={searchRef}></input>
          </form>
      </div>
    <div className="photos">
      <ul className="images-ul">
          {imageUrls.map((image, index) => {
            // if (imageUrls.length === index + 1) {
            //     return <img ref={lastImageElementRef} key={image} src={image} />
            // } else {
              return ( 
                <li 
                  key={index} 
                  onClick={() => setCurrentImage(image)}
                >
                  <LazyLoadImage
                        effect='blur'
                        key={index} 
                        src={image}
                        alt=''
                        scrollPosition={scrollPosition}
                        height="400px"
                        width="600px"  
                  />
                </li>
              )
          })} 
      </ul>
    </div>
    { currentImage && <Modal currentImage={currentImage} setCurrentImage={setCurrentImage} currentArtist={currentArtist}  setCurrentArtist={setCurrentArtist} /> }
    {loading && <div className="loader"></div>}
  </div>
  
  )
}

export default trackWindowScroll(App);
