import React from 'react'

const Modal = ({ currentImage, setCurrentImage, currentArtist, setCurrentArtist }) => {

    const handleClick = (e) => {
        if(e.target.classList.contains('backdrop')) {
            setCurrentImage(null)
            setCurrentArtist(null)
            
        }
    }
    return (
        <div className="backdrop" onClick={handleClick}>
            <img 
                src={currentImage}
                alt="big"
            />
            <p>{currentArtist}</p>
        </div>
    )
}

export default Modal